.mobile-block {
  display: none;
}

.btn-mobile {
  display: none;
}
@media (max-width: 992px) {
  /* small tablet devices */
  #DesktopContainer {
    flex-wrap: nowrap;
  }
  #Desktop{
    display: none;
  }
}
@media all and (max-width: 768px) {
  /* mobile devices */
  .mobile-block {
    display: block !important;
  }

  .mobile-hide {
    display: none !important;
  }

  .btn-mobile {
    display: inline-block !important;
  }

  .content-header {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
  }
  .content-header .content-title {
    margin-bottom: 1rem;
  }

  .card-header input.form-control {
    margin-bottom: 1rem;
  }

  .order-info-wrap .icontext {
    margin-bottom: 1rem;
  }

  .table-responsive table {
    min-width: 700px;
  }
}
