@charset "UTF-8";
/* ==== CUSTOM UI ELEMENTS AND STYLES ====*/
/* ========================
INCLUDE STYLES AND COMPONENTS
===================== */
/* ================== HELPERS =================== */
.table thead th {
  color: #adb5bd;
  font-weight: 400;
}
.table-lg td,
.table-lg th {
  padding: 0.8rem 1rem;
}
tbody td {
  border-bottom: 0;
}
tbody tr {
  border-bottom: 1px solid #cfdbe6;
}
tbody tr:hover {
  background-color: #ebf0fd !important;
}
a.disabled {
  pointer-events: none;
}

.img-wrap img {
  width: 100%;
  height: 250px;
  object-fit: contain;
}

/* =================  DEFINED VARIABLES =================== */
.icon-xs,
.icon-sm,
.icon-md,
.icon-lg {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-shrink: 0;
  flex-grow: 0;
}

.icon-xs {
  width: 32px;
  height: 32px;
  line-height: 32px !important;
  font-size: 16px;
}

.icon-sm {
  width: 48px;
  height: 48px;
  line-height: 48px !important;
  font-size: 20px;
}

.icon-md {
  width: 60px;
  height: 60px;
  line-height: 60px !important;
  font-size: 28px;
}

.icon-lg {
  width: 96px;
  height: 96px;
  line-height: 96px !important;
  font-size: 42px;
}

.img-avatar {
  border-radius: 100%;
  border: 3px solid #fff;
}

.img-xs,
.img-sm,
.img-md,
.img-lg {
  object-fit: cover;
  flex-shrink: 0;
  flex-grow: 0;
}

.img-xs {
  width: 40px;
  height: 40px;
}

.img-sm {
  width: 60px;
  height: 60px;
}

.img-md {
  width: 112px;
  height: 112px;
}

.img-lg {
  width: 196px;
  height: 196px;
}

.center-xy {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.center-y {
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
}

.center-x {
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

/* ================== BASE =================== */
body {
  position: relative;
  min-height: 100vh;
}

.content-main {
  padding: 30px 3%;
  margin-left: auto;
  margin-right: auto;
}
.nav-item img.rounded-circle {
  border: 2px solid #eee;
}
.nav-item img.rounded-circle:hover {
  border-color: #4fa607;
}

.content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.content-header .content-title {
  margin-bottom: 0;
}

.inner {
  padding: 1rem;
}
.menu-aside {
  list-style: none;
  margin: 0.5rem;
  padding: 0;
}
.menu-aside a {
  display: block;
  text-decoration: none;
}
.icon {
  color: #adb5bd;
  margin-right: 10px;
  font-size: 18px;
}
.menu-aside .active {
  background-color: #ebf0fd;
}
.menu-link {
  padding: 10px;
  color: #29335d;
  font-weight: bold;
  border-radius: 0.3rem;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.menu-link:hover {
  transition: 0.2s linear;
  background-color: #e9ecef;
}

.nav-pills .nav-link {
  color: #6c757d;
  font-weight: 500;
}
.nav-pills .nav-link:hover {
  color: #141432;
  background-color: rgba(173, 181, 189, 0.15);
}
/* ==================  title helpers ================ */
.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}

/* ITEMSIDE */
.itemside {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
}
.itemside .aside,
.itemside .left {
  position: relative;
  flex-shrink: 0;
}
.itemside .info {
  padding-left: 15px;
  padding-right: 7px;
}
.itemside p {
  margin-bottom: 0;
}
.itemside .title {
  display: block;
  margin-bottom: 5px;
}
.itemside a.title:hover {
  color: #4fa607;
}

a.itemside {
  color: initial;
  text-decoration: none;
}
a.itemside:hover .title {
  text-decoration: underline;
}

/* --------- description list --------*/
[className*="dlist"] {
  margin-bottom: 5px;
}

.dlist-inline dt,
.dlist-inline dd {
  display: inline-block;
}

.dlist {
  display: flex;
}
.dlist dt {
  width: 150px;
  font-weight: normal;
}
.dlist dd {
  margin-left: 30px;
  vertical-align: baseline;
  flex-grow: 1;
  margin-bottom: 0;
  text-align: right;
}

/* -------------------- list.row--------------------- */
ul.row,
ul.row-sm {
  list-style: none;
  padding: 0;
}

/* ================= lists ================= */
[className*="list-"] li:after {
  visibility: hidden;
  display: block;
  content: "";
  overflow: hidden;
  height: 0;
  clear: both;
}

.icontext {
  display: inline-flex;
  align-items: center;
  padding: 5px;
}
.icontext .icon {
  position: relative;
  flex-shrink: 0;
  margin-right: 10px;
}
.icontext small,
.icontext .title {
  display: block;
}

.tag {
  display: inline-block;
  padding: 3px 7px;
  background: #f2f2f2;
  border: 1px xolid #eee;
  border-radius: 3px;
  margin-top: 4px;
  margin-right: 2px;
  font-size: 85%;
}

/* ====================== box ==================== */
.box {
  padding: 1rem;
  border-radius: 0.25rem;
  border: 1px solid rgba(108, 117, 125, 0.2);
  background: #fff;
}
.box img {
  max-width: 100%;
}

.form-control:hover,
.form-select:hover {
  border-color: #4fa607;
}

.btn-sm i {
  font-size: 1.2rem;
  line-height: 0.7;
  vertical-align: bottom;
}